.manage-form-opensource {
  height: 405px;
  padding: 20px 20px 19px 20px;
}

.manage-form-opensource-close {
  height: 55px;
  padding: 14px 20px 13px 20px;
}

.manage-form-opensource > span:first-child,
.manage-form-opensource-close > span:first-child {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #262c31;
}

.manage-form-opensource div:first-child,
.manage-form-opensource-close div:first-child {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.Manage-form-textarea {
  padding: 13px 21px 15px 21px;
  resize: none;
  box-sizing: border-box;
  width: 884px;
  height: 273px;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  font-family: "Pretendard";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #5a636a;
}

.Manage-form-textarea::-webkit-scrollbar {
  width: 13px;
  background-color: #ffffff00;
}

.Manage-form-textarea::-webkit-scrollbar-thumb {
  width: 3px;
  background: #adb4ba;
  background-clip: padding-box;
  border: 5px solid transparent;
  border-radius: 10px;
}

.manage-form-opensource div span,
.manage-form-opensource-close div span {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #262c31;
}

.Manage-form-save-opensource button {
  bottom: 19px;
}
