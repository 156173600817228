.Manage-contents-table-program > span:first-child {
  margin-bottom: 27px;
}

.Manage-program-filter {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.Manage-program-filter a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51px;
  height: 35px;
  background: #a3a7aa;
  border-radius: 21px;
  margin-right: 9px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
}

.Manage-program-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.Manage-program-search-number span {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #adb4ba;
}

.Manage-program-search-number span:nth-child(2) {
  margin-left: 7px;
  color: #262c31;
}

.Manage-program-search {
  display: flex;
}

.Manage-program-search div:first-child {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 244px;
  height: 36px;
  background: #f2f4f5;
  border-radius: 18px;
}

.Manage-program-search div:first-child span {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #b8bfc4;
}

.Manage-program-search div:first-child img {
  width: 16px;
  height: 16px;
}

.Manage-program-search div:nth-child(2) {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding: 7px 16px 6px 14px;
  background: #262c31;
  border-radius: 18px;
  margin-left: 16px;
  cursor: pointer;
}

.Manage-program-search div:nth-child(2) img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.Manage-program-search div:nth-child(2) span {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
}

.manage-table-program table tbody tr td {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #636c73;
}

.manage-table-program table thead tr th:first-child,
.manage-table-program table tbody tr td:first-child {
  text-align: left;
  padding-left: 42px;
  width: 116px;
}

.manage-table-program table tbody tr td:first-child a {
  display: flex;
  align-items: center;
  height: 59px;
  box-sizing: content-box;
}

.manage-table-program table tbody tr td:first-child span {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #262c31;
  margin-right: 10px;
}

.manage-table-program table tbody tr td:first-child img {
  width: 28px;
  height: 28px;
}

.manage-table-program table thead tr th:nth-child(2),
.manage-table-program table tbody tr td:nth-child(2) {
  text-align: left;
  width: 341px;
}

.manage-table-program table tbody tr td:nth-child(2) span {
  display: block;
  width: 295px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #636c73;
}

.manage-table-program table thead tr th:nth-child(3),
.manage-table-program table tbody tr td:nth-child(3) {
  text-align: left;
  width: 201px;
}

.manage-table-program table thead tr th:nth-child(4),
.manage-table-program table tbody tr td:nth-child(4) {
  text-align: left;
  width: 241px;
}

.manage-table-program table thead tr th:last-child,
.manage-table-program table tbody tr td:last-child {
  text-align: center;
  width: 57px;
  padding-right: 45px;
}

.manage-table-program table tbody tr td:last-child a {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 29px;
  text-align: center;
  background-color: #5a636a;
  border: 1px solid #5a636a;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
}

.manage-table-program table tbody tr td:last-child a img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.table-sub-menu-program {
  left: 13%;
  width: 100px;
}
