.go-back-container-general-member-seemore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 924px;
  box-sizing: border-box;
}

.go-back-container-general-member-seemore div:nth-child(2) {
  display: flex;
  margin-bottom: 19px;
}

.go-back-container-general-member-seemore div:nth-child(2) a:first-child {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  width: 65px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #ef594b;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #ef594b;
}

.go-back-container-general-member-seemore div:nth-child(2) a:nth-child(2) {
  background: #ffffff;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #262c31;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  margin-left: 7px;
}

.manage-form-general-member-see-more {
  padding: 47px 48px 50px 48px;
  min-height: 823px;
  height: auto;
}

.manage-form-general-member-see-more > div {
  margin-bottom: 26px;
}

.manage-form-general-member-see-more > span {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #262c31;
  display: block;
  margin-bottom: 29px;
}

.general-member-agreement-checkbox {
  align-items: flex-start;
}

.general-member-agreement-checkbox:last-of-type {
  margin-bottom: 0px;
}

.seemore-consent-checkbox {
  display: flex;
  align-items: center;
  margin-right: 7px;
  margin-bottom: 18px;
}

.general-member-agreement-checkbox
  > div
  .seemore-consent-checkbox:last-of-type {
  margin-bottom: 0px;
}

.seemore-consent-checkbox span {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #5a636a;
}

.seemore-consent-checkbox input {
  width: 20px;
  height: 20px;
  border: 1.5px solid #636c73;
  border-radius: 4px;
  appearance: none;
}

.seemore-consent-checkbox input:checked {
  border: 1.5px solid #36a6e6;
  background-color: #36a6e6;
  background-image: url("/public/img/check.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.manage-form-general-member-see-more
  .Manage-form-view-details
  span:nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #5a636a;
}

.general-member-info-flex-start {
  align-items: flex-start;
}

.general-member-info-img-container {
  display: flex;
  align-items: flex-end;
}

.general-member-info-img-container div {
  border: 1px solid #eef2f3;
  border-radius: 34px;
  padding: 6px 10px 6px 13px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: 6px;
}

.general-member-info-img-container div span {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #262c31;
  margin-right: 5px;
}

.general-member-info-img-container div img {
  width: 24px;
  height: 24px;
}
