.Manage-registration-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdfdfd;
  width: 100%;
  min-height: 100%;
  height: 100vh;
}

.Manage-registration-complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 514px;
  height: 761px;
  background: #ffffff;
  box-shadow: 4px 8px 36px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 311px 85px 87px 86px;
}

.Manage-registration-complete h1 {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 22px;
  color: #000000;
  margin: 0px;
  margin-bottom: 26px;
}

.Manage-registration-complete span {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #858987;
  margin-bottom: 245px;
}

.Manage-registration-complete-button {
  width: 343px;
  height: 48px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #004098;
  border-radius: 3px;
  border: none;
}
