.go-back-container-gathering-schedule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 924px;
  box-sizing: border-box;
}

.manage-form-gathering-schedule-add {
  position: relative;
  padding: 48px 48px 50px 48px;
  height: 734px;
  background: #f7f7f7;
  border: 1px solid #dfe1e4;
  border-radius: 8px;
}

.Manage-input-title-schedule {
  width: 120px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.003em;
  color: #adb4ba;
}

.manage-form-gathering-schedule > div {
  margin-bottom: 30px;
}

.manage-form-gathering-schedule > div:first-child {
  width: 828px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.manage-form-gathering-schedule > div:first-child > span:first-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #262c31;
}

.manage-form-gathering-schedule div:first-child a {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #262c31;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  box-sizing: border-box;
}

.gathering-period-schedule {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gathering-period-schedule input {
  width: 285px;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid #dfe1e4;
  border-radius: 8px;
}

.gathering-period-schedule input:first-child {
  margin-bottom: 0px;
}

.gathering-period-schedule input:last-child[type="date"] {
  background: url(/public/img/calendar.svg) right 10px center no-repeat;
}

.gathering-period-schedule input[type="date"]::before {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #adb4ba;
}

.gathering-period-schedule span {
  margin: 0 12px;
}

.gathering-period-schedule > div {
  background-color: #ffffff;
}

.gathering-period-schedule a {
  background: #5a636a;
  border: 1px solid #5a636a;
  border-radius: 4px;
  display: block;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  letter-spacing: -0.003em;
  padding: 13px 26px;
  margin-left: 15px;
}

.save-gathering-add button {
  position: absolute;
  right: 27px;
  bottom: 30px;
}
