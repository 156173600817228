.Manage-registration-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdfdfd;
  width: 100%;
  min-height: 100%;
  height: 100vh;
}

.Manage-registration {
  width: 514px;
  height: 761px;
  background: #ffffff;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 101px 85px 87px 86px;
}

.Manage-registration h1 {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 22px;
  color: #000000;
}

.Manage-registration-form {
  display: flex;
  flex-direction: column;
}

.Manage-registration-form > span {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  display: block;
  margin-bottom: 10px;
}

.Manage-registration-form input {
  height: 48px;
  background: #ffffff;
  border: 1px solid #c8cbca;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 13px 15px;
  margin-bottom: 15px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #262c31;
}

.Manage-registration-form input:nth-of-type(3) {
  margin-bottom: 7.08px;
}

.Manage-registration-form input:nth-of-type(4) {
  margin-bottom: 40px;
}

.Manage-registration-form input::placeholder {
  appearance: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #858987;
}

.Manage-registration-form-registration {
  height: 48px;
  background: #c8cbca;
  border-radius: 3px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-bottom: 10.08px;
}

.Manage-registration-form-registration {
  height: 48px;
  background: #004098;
  border-radius: 3px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 10.08px;
}

.Manage-registration-form-pw-note {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #a3a7aa;
  margin-bottom: 15px;
}
