.admin-header {
  width: 100%;
  height: 70px;
  background-color: #fff;
  border-bottom: 1px solid #f2f4f5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-header-item {
  margin-right: 14.32%;
  display: flex;
  align-items: center;
}

.admin-header-item img {
  width: 38px;
  height: 38px;
}

.admin-header-item span {
  color: #262c31;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  margin-left: 7px;
}

.admin-header-item button {
  background-color: #f2f4f5;
  color: #636c73;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  border: none;
  border-radius: 24px;
  padding: 7px 18px;
  margin-left: 17px;
  cursor: pointer;
}
