.go-back-container-catagory-adit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 924px;
  box-sizing: border-box;
}

.go-back-container-catagory-adit div:nth-child(2) {
  display: flex;
  margin-bottom: 19px;
}

.go-back-container-catagory-adit div:nth-child(2) a {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  width: 65px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #ef594b;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #ef594b;
  margin-right: 7px;
}

.manage-form-catagory-adit {
  padding: 48px;

  height: 260px;
}

.manage-form-catagory-adit > span {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #262c31;
  display: block;
  margin-bottom: 29px;
}

.manage-form-catagory-adit-input {
  width: 366px;
}

.manage-form-save-catagory-adit {
  bottom: 30px;
}
